import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-form-button',
    template: `
        <button
            type="{{type}}"
            (click)="o_click.emit($event)"
            [disabled]="disabled"
            class="{{size}}"
            [ngClass]="{dimmed: dimmed, branding: branding}"
        >
            <ng-content></ng-content>
        </button>
    `,
    styles: [`
        button {
            padding: 10px 30px;
            background: var(--color-primary);
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            font-size: 16px; 
            line-height: 22px;
            font-weight: 600;
        }

        button.small {
            padding: 4px 8px 3px 8px;
            font-size: 12px;
        }

        button.dimmed {
            background: #999;
        }

        button.branding {
            background: var(--dashboard-color);
        }

        button {
            cursor: pointer;
        }

        button:focus, button:hover {
            outline: none;
        }

        button:disabled {
            cursor: default;
            opacity: 0.4;
        }
    `]
})
export class FormButtonComponent {
    @Input() type = 'button';
    @Input() disabled = false;
    @Input() size = '';
    @Input() dimmed = false;
    @Input() branding = false;
    @Output() o_click = new EventEmitter();
}
