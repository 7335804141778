import { NgModule } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormModule } from '../../form/form.module';
import { FormButtonModule } from '../../form/button/module';
import { CallStartRecordingComponent } from './recording/call-start-recording.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        TranslateModule,
        FormModule,
        FormButtonModule,
        MatDialogModule,
    ],
    declarations: [
        CallStartRecordingComponent,
    ],
    exports: [
        CallStartRecordingComponent
    ],
    /* Unused for now */
    /* providers: [
        CurrencyPipe,
    ], */
})
export class CallModule {
}
