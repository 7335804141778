import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class IFrameService {
    subscriptionLangChange: Subscription = null;

    constructor(private translate: TranslateService) {
        if (!this.subscriptionLangChange) {
            this.subscriptionLangChange = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.changeLanguage(event.lang);
            });
        }
    }

    changeUrl(url) {
        url = `/${this.translate.currentLang}${url}`;
        window.parent.postMessage({
            function: 'changeURL',
            url: url,
        }, '*');
    }

    changeLanguage(locale_code) {
        window.parent.postMessage({
            function: 'changeLanguage',
            locale_code: locale_code,
        }, '*');
    }
}
