
import {throwError as observableThrowError} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import { ErrorService } from './error.service';
import {CustomEncoder} from './custom.encoder';

@Injectable()
export class ApiService {

    constructor(private translate: TranslateService,
                private http: HttpClient,
                private errorService: ErrorService) {
    }

    request(data) {
        data = Object.assign({
            method: 'get',
            url: '',
            params: {},
            upload: false,
            applicationHashPublic: false,
            userHash: null,
        }, data);
        const url = environment.btnImApiBase + data.url;
        let body;
        /**
         * Headers
         */
        const headers = {};
        if (typeof this.translate.currentLang !== 'undefined') {
            headers['locale_code'] = this.translate.currentLang;
        }
        // todo: load appropriate hash
        if (data.applicationHashPublic === true) {
            headers['application_hash_public'] = environment.hashPublic;
        }
        if (data.method === 'post' && data.upload === false) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        /**
         * Params
         */
        if (data.method === 'post' || data.method === 'put') {
            if (data.upload === false) {
                body = new HttpParams({encoder: new CustomEncoder()});
                if (data.userHash) {
                    body = body.set('user_hash', data.userHash);
                }
                for (const key in data.params) {
                    if (data.params.hasOwnProperty(key)) {
                        body = body.set(key, data.params[key]);
                    }
                }
            } else {
                body = new FormData();
                if (data.userHash) {
                    body = body.append('user_hash', data.userHash);
                }
                for (const key in data.params) {
                    if (data.params.hasOwnProperty(key)) {
                        body.append(key, data.params[key]);
                    }
                }
            }
        } else if (data.method === 'get' || data.method === 'delete') {
            if (data.userHash) {
                data.params['user_hash'] = data.userHash;
            }
        }
        /**
         * Request
         */
        const requestOptions = {
            headers: headers,
        };
        if (data.headers === true) {
            requestOptions['observe'] = 'response';
        }
        if (data.method === 'post') {
            return this.http.post(url, body, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(new Error(error));
            }));
        } else if (data.method === 'put') {
            return this.http.put(url, body, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(new Error(error));
            }));
        } else if (data.method === 'delete') {
            requestOptions['params'] = data.params;
            return this.http.delete(url, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(new Error(error));
            }));
        } else if (data.method === 'get') {
            requestOptions['params'] = data.params;
            return this.http.get(url, requestOptions).pipe(catchError((error) => {
                this.errorService.log(error);
                return observableThrowError(new Error(error));
            }));
        }
    }
}
