import { NgModule } from '@angular/core';
import { FormModule } from '../form/form.module';
import { FormiFrameDialogModule } from '../form/iframe/module';
import { TranslateModule } from '@ngx-translate/core';
import { CookiesConsentComponent } from './cookies-consent-component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        TranslateModule,
        FormModule,
        FormiFrameDialogModule,
        MatDialogModule,
    ],
    declarations: [
        CookiesConsentComponent,
    ],
    exports: [
        CookiesConsentComponent,
    ],
})
export class CookiesConsentModule {
}
