import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';

@Injectable()
export class ExpertService {
    expert: any = false;

    constructor(private translate: TranslateService,
                private apiService: ApiService) {
    }

    expertGet(expertSlug, borderless, expertSelf) {
        return this.apiService.request({
            method: 'get',
            url: '/users/',
            applicationHashPublic: true,
            params: {
                slug: expertSlug,
                borderless: (borderless.b || borderless.path === 'borderless') ? 1 : 0,
                expertSelf: expertSelf ? 1 : 0
            }
        });
    }

    expertGetById(expertId) {
        return this.apiService.request({
            method: 'get',
            url: '/users/',
            applicationHashPublic: true,
            params: {
                expert_id: expertId,
            }
        });
    }

    async expertLengths(expertHash) {
        return new Promise(async (resolve, reject) => {
            try {
                const lengths = await this.apiService.request({
                    method: 'get',
                    url: '/users/lengths/',
                    applicationHashPublic: true,
                    params: {
                        user_hash: expertHash,
                    }
                }).toPromise();
                resolve(lengths);
            } catch (error) {
                reject(error);
            }
        });
    }

    expertAvailability(expertHash, length, interval, breakBetween, lengthId, debug, expertSelf) {
        return this.apiService.request({
            method: 'get',
            url: '/availability/',
            params: {
                user_hash: expertHash,
                length: length,
                interval: interval,
                break_between: breakBetween,
                length_id: lengthId,
                debug: debug ? 1 : 0,
                booked_by_expert: expertSelf ? 1 : 0,
            }
        });
    }
}
