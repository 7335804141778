import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { EventService } from './event.service';
import * as Cookies from 'js-cookie';
import * as moment from 'moment-timezone';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AuthService {
    guest: any = false;

    guestStatusChangeSource: Subject<any> = new Subject<any>();
    openStartRecordingSource: Subject<any> = new Subject<any>();

    guestStatusChange: Observable<any> = this.guestStatusChangeSource.asObservable();
    openStartRecording: Observable<any> = this.openStartRecordingSource.asObservable();

    constructor(private eventService: EventService,
                private apiService: ApiService) {
    }

    initializer() {
        return new Promise<void>(async (resolve, reject) => {
            // if guest we should not connect to socket here
            const socketParams = {};

            if (window.location.pathname.indexOf('/profile/appointments') && this.getParameterByName('guest_hash')) {
                const guestHash = this.getParameterByName('guest_hash');
                try {
                    const guest = await this.guestGet(guestHash);
                    this.guestSet(guest, false);
                    socketParams['guestHash'] = guest['hash'];
                } catch (error) { reject(); }
            }

            this.socketConnect(socketParams);
            resolve();
        });
    }

    getParameterByName(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
            results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    socketConnect(socketParams = null) {
        this.eventService.connect(socketParams);
    }

    guestGet(guestHash) {
      return new Promise((resolve) => {
          this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/guests',
            params: {
                guest_hash: guestHash,
            },
          }).subscribe((response) => {
            this.guestSet(response);
            resolve(response);
          }, () => {
            this.guestSet(false);
            resolve(null);
          });
      });
    }

    /**
     * Set guest
     * @param guest
     */
    guestSet(guest, connectToSocket = false) {
      this.guest = guest;
      if (this.guest) {
        moment.tz.setDefault(this.guest.timezone);
      }
      this.guestStatusChangeSource.next(this.guest);
      if (connectToSocket) {
        this.socketConnect(this.guest);
      }
    }

    /**
     * Set guest
     * @param data data of the guest to register
     * @param shouldGuestSet whether we should set the guest attribute in the service or not
     */
    addGuest(data, shouldGuestSet) {
        /* adding phone code to the phone */
        if (data['phone'] && data['phone_code'] && !data['phone'].startsWith(' ')) {
            data['phone'] = data['phone_code'] + ' ' + data['phone'];
        }
        if (data['phone'] && data['phone_code'] && data['phone'].startsWith(' ')) {
            data['phone'] = data['phone_code'] + data['phone'];
        }
        delete data['phone_code'];
        return this.apiService.request({
            method: 'post',
            url: '/guests/',
            params: data,
            applicationHashPublic: true,
        }).pipe(map((res) => {
          if (shouldGuestSet) {
            this.guestSet(res);
          }
          return res;
        }));
    }

    guestEmailAvailable(email) {
        return this.apiService.request({
            method: 'get',
            url: '/guests/email/',
            params: {
                email: email,
            },
            applicationHashPublic: true,
        }).pipe(map(res => res === 0));
    }

    /**
     * Set cookies consent
     */
    setTrackingCookie() {
        Cookies.set(environment.trackingCookie, true, {
            expires: 180,
            path: environment.cookiePath,
            domain: window.location.hostname,
            secure: environment.cookieSecure,
        });
    }

    /**
     * Get cookies consent
     */
    getTrackingCookie() {
        return Cookies.get(environment.trackingCookie, {
            path: environment.cookiePath,
            domain: window.location.hostname,
            secure: environment.cookieSecure,
        });
    }
}
