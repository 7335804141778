import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class TeamService {

    constructor(private apiService: ApiService) {}

    teamGet(teamSlug) {
        return this.apiService.request({
            method: 'get',
            url: '/teams/public_info',
            applicationHashPublic: true,
            params: {
                slug: teamSlug
            }
        });
    }

    async teamMeetingTypes(teamId) {
        return new Promise(async (resolve, reject) => {
            try {
                const lengths = await this.apiService.request({
                    method: 'get',
                    url: '/users/lengths/',
                    btnIm: true,
                    params: {
                        team_id: teamId
                    }
                }).toPromise();
                resolve(lengths);
            } catch (error) {
                reject(error);
            }
        });
    }

    teamAvailability(teamId, length, interval, breakBetween, lengthId, debug) {
        return this.apiService.request({
            method: 'get',
            url: '/teams/availability/',
            params: {
                team_id: teamId,
                length: length,
                interval: interval,
                break_between: breakBetween,
                length_id: lengthId,
                debug: debug ? 1 : 0,
                booked_by_expert: 0,
            }
        });
    }
}
