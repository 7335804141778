import { ErrorHandler, Injectable } from '@angular/core';
import * as Raven from 'raven-js';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorService implements ErrorHandler {
    status: number;
    constructor() {
        Raven.config('https://04f0845229794b2aad094f8bb5a66c84@sentry.io/1226956', {
            autoBreadcrumbs: environment.production,
        }).install();
    }

    handleError(error: Error): void {
        this.ravenLog(error);
    }

    ravenLog(error: Error) {
        if (environment.production) {
            Raven.captureException(error);
        } else {
          console.error(error);
        }
    }

    log(error: any, message = 'Error') {
        console.error(error);
        if (!!error.error) {
            this.status = error.error.status;
        }
        if (environment.production) {
            this.ravenLog(new Error(message));
        }
    }
}
