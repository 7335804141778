import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../../shared/api.service';
import { AuthService } from '../../../shared/auth.service';

@Component({
    selector: 'app-start-recording',
    templateUrl: './call-start-recording.component.html',
    styleUrls: ['./call-start-recording.component.css']
})

export class CallStartRecordingComponent implements OnInit {
    startRecording = false;
    showRecordings = false;
    recordings: any;
    appointmentId;
    queryParams = '';
    loading = false;
    recordingError = false;

    constructor(public dialogRef: MatDialogRef<CallStartRecordingComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private apiService: ApiService,
                private authService: AuthService) {
        const { startRecording, showRecordings, appointmentId } = data;
        this.startRecording = startRecording;
        this.showRecordings = showRecordings;
        this.appointmentId = appointmentId;
    }

    ngOnInit() {
        if (this.showRecordings) {
            this.loading = true;
            this.apiService.request({
                method: 'post',
                btnIm: true,
                url: '/appointments/archive-recordings/guest',
                params: {
                    appointment_id: this.appointmentId,
                    guest_hash: this.authService.guest.hash,
                },
            }).subscribe((response) => {
                this.loading = false;
                this.recordings = response;
                this.recordings.map(recording => {
                    if (!recording.url) {
                        this.recordingError = true;
                    }
                });
            }, (error) => {
                console.error(error);
            });
        }
    }

    close() {
        this.dialogRef.close();
    }
}
