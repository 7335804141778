import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogConfirmComponent } from './dialog-confirm/component';

@Injectable()
export class DialogHelper {
    constructor(private matDialog: MatDialog,
                private translateService: TranslateService) {
    }

    open(data = {}) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.matDialog.open(DialogConfirmComponent, {
                width: data['width'] ? data['width'] : '260px',
                maxWidth: '100%',
                maxHeight: '100%',
                data: {
                    content: data['content'] ? data['content'] : this.translateService.instant('DIALOG.CONFIRM.CONTENT'),
                    no: data['no'] ? data['no'] : this.translateService.instant('DIALOG.CONFIRM.NO'),
                    yes: data['yes'] ? data['yes'] : this.translateService.instant('DIALOG.CONFIRM.YES'),
                },
                disableClose: data['disableClose'] ? true : false
            });
            dialogRef.beforeClosed().subscribe(confirmed => {
                if (confirmed) {
                    resolve();
                } else {
                    reject();
                }
            });
        });
    }
}
