import { NgModule } from '@angular/core';
import { FormModule } from '../../form/form.module';
import { FormiFrameDialogComponent } from './component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        FormModule,
        MatDialogModule,
    ],
    declarations: [
        FormiFrameDialogComponent,
    ],
    exports: [
        FormiFrameDialogComponent,
    ],
})
export class FormiFrameDialogModule {
}
