import { Component } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LoadingService } from './shared/loading.service';
import { EventService } from './shared/event.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { Title } from '@angular/platform-browser';
import * as locales from '../assets_clients/locales.json';
import { CallStartRecordingComponent } from './profile/call/recording/call-start-recording.component';
import { ExpertService } from './shared/expert.service';
import { ApiService } from './shared/api.service';
import { componentDestroyed, OnDestroyMixin } from '@w11k/ngx-componentdestroyed';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styles: [`
        .underline {
            text-decoration: underline;
        }
    `],
})
export class AppComponent extends OnDestroyMixin {
    guest: any = false;
    currentLanguage = '';
    loading = false;
    expert: any = false;
    notificationOptions = {
        position: ['top', 'right'],
        timeOut: 0,
        showProgressBar: true,
        pauseOnHover: true,
        clickToClose: true,
        maxStack: 10,
        icons: {
            success: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            alert: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            error: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            info: '<i class="material-icons" style="font-size: 2rem;">close</i>',
            warn: '<i class="material-icons" style="font-size: 2rem;">close</i>'
        },
    };
    borderless = false;
    groupCall: Boolean = false;
    roomCall: Boolean = false;
    inprofile = false;
    custom_domain = false;
    waitingForLogo = true;
    urlDataPolicy = '';
    urlIncludesExpert = false;
    urlIncludesTeam = false;
    scriptsAllowed = false;

    constructor(public authService: AuthService,
                private localize: LocalizeRouterService,
                private router: Router,
                private eventService: EventService,
                private apiService: ApiService,
                private translate: TranslateService,
                private loadingService: LoadingService,
                public dialog: MatDialog,
                private titleService: Title,
                private activatedRoute: ActivatedRoute,
                private expertService: ExpertService) {
        super();
        document.documentElement.style.setProperty('--dashboard-color', '#00C6FF');
        document.documentElement.style.setProperty('--dashboard-color-10-opacity', '#00C6FF10');
        document.documentElement.style.setProperty('--dashboard-color-20-opacity', '#00C6FF20');
        this.guest = authService.guest;
        const currentLang = this.translate.currentLang === 'en' ? '' : this.translate.currentLang;
        this.currentLanguage = currentLang;

        /* Special script to load for this domain */
        if (window.location.hostname === 'appts.agemd.com') {
            this.loadGTMScriptAgeMD();
        }

        router.events.pipe(takeUntil(componentDestroyed(this))).subscribe((event) => {
            if (window.location.href.includes(environment.btnImClientApp)) {
                this.custom_domain = false;
            } else {
                this.custom_domain = true;
            }
            if (window.location.href.includes('/e/')) {
                this.urlIncludesExpert = true;
            }
            if (window.location.href.includes('/t/')) {
                this.urlIncludesTeam = true;
            }
            // Page loading
            if (event instanceof NavigationStart) {
                loadingService.add('navigation');
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                loadingService.remove('navigation');
            }
            // No language fix
            if (event instanceof RoutesRecognized && ['', '/'].indexOf(event.url) < 0 && event.url !== event.urlAfterRedirects) {
                // Check if URL contains language
                const regexp = '^\\/?(' + locales['locales'].join('|') + ')\\/?';
                if (event.url.match(regexp) === null) {
                    this.router.navigate([this.localize.translateRoute(event.url)]);
                }
            }
            // Check if URL contains option borderless or groupCall or roomCall
            if (event instanceof RoutesRecognized) {
                const regexp = 'b=true';
                if (!event.url.includes('/e/')) {
                    this.allowScripts();
                }
                if (event.url.match(regexp) || event.url.includes('borderless')) {
                    this.borderless = true;
                }
                if (event.url.includes('group')) {
                    this.groupCall = true;
                }

                if (event.url.includes('/r/')) {
                    this.roomCall = true;
                }
            }
            // Check if URL is not profile (then booking page))
            if (event instanceof RoutesRecognized) {
                if (event.url.includes('/profile')) {
                    this.inprofile = true;
                }
                let clientTitle = '';
                if (event.url.includes('/group')) {
                    clientTitle = 'PAGE_TITLE.VIDEOCALL';
                } else if (event.url.includes('/profile/appointments')) {
                    clientTitle = 'PAGE_TITLE.MEETINGS';
                }
                if (clientTitle.length > 1) {
                    this.changeTitle(this.translate.instant(clientTitle));
                }
            }

            if (event instanceof NavigationEnd) {
                const snapshot = this.getSnapshotByDataKey(activatedRoute.snapshot, 'isExpertPage');
                if (snapshot !== false) {
                    this.expert = snapshot.data.expert[0];
                    this.getUrlDataPolicy(this.expert['slug']);
                }
                /* TODO : get team colors and pictures */
                if (!this.expert && this.guest && this.guest.expert_id) {
                    this.expertService.expertGetById(this.guest.expert_id).pipe(takeUntil(componentDestroyed(this))).subscribe(expert => {
                        this.expert = expert;
                        this.getUrlDataPolicy(this.expert['slug']);
                        this.waitingForLogo = false;
                        if (this.expert.favicon) {
                            const link = document.createElement('link');
                            const oldLink = document.getElementById('dynamic-favicon');
                            link.id = 'dynamic-favicon';
                            link.rel = 'shortcut icon';
                            link.href = this.expert.favicon;
                            if (oldLink) {
                                document.head.removeChild(oldLink);
                            }
                            document.head.appendChild(link);
                        }
                    });
                } else {
                    this.waitingForLogo = false;
                }
            }
        });

        authService.guestStatusChange.pipe(takeUntil(componentDestroyed(this))).subscribe((guest: any) => {
          this.guest = guest;
        });
        loadingService.change.pipe(takeUntil(componentDestroyed(this))).subscribe((keys: Array<string>) => {
            this.loading = keys.length > 0;
        });
        this.translate.onLangChange.pipe(takeUntil(componentDestroyed(this))).subscribe((event: LangChangeEvent) => {
            moment.locale(event.lang);
        });
        moment.locale(this.translate.currentLang);

        authService.openStartRecording.pipe(takeUntil(componentDestroyed(this))).subscribe((data: any = null) => {
            this.dialog.open(CallStartRecordingComponent, {
                maxWidth: '850px',
                data
            });
        });
        this.eventService.oNotificationClosed.pipe(takeUntil(componentDestroyed(this))).subscribe((data: any) => {
            if (data['type']['url'] === 'appointments') {
                this.router.navigate([this.localize.translateRoute('/profile/appointments/')]);
            }
        });
    }

    makeHttpLink(link) {
        if (!link.match(/^((http|https):\/\/)/)) {
            link = `https://${link}`;
        }
        return link;
    }

    getUrlDataPolicy(slug) {
        this.apiService.request({
            method: 'get',
            applicationHashPublic: true,
            btnIm: true,
            url: '/documents/terms/',
            params: {
                slug: slug,
            }
        }).subscribe((document) => {
            this.urlDataPolicy = this.translate.currentLang === 'de' ? 'https://meetfox.com/datenschutz_alone/' : 'https://meetfox.com/data_policy_alone/';
            if (document) {
                if (document['data_policy'].length > 1) {
                    this.urlDataPolicy = this.makeHttpLink(document['data_policy']);
                }
            }
        }, (error) => {
            console.error(error);
        });
    }

    getSnapshotByDataKey(snapshot: ActivatedRouteSnapshot, dataKey) {
        if (snapshot.data && typeof snapshot.data[dataKey] !== 'undefined') {
            return snapshot;
        } else if (snapshot.children.length) {
            return this.getSnapshotByDataKey(snapshot.children[0], dataKey);
        }
        return false;
    }

    changeLanguage(lang: string) {
        if (this.currentLanguage !== lang) {
            const { snapshot: { queryParams: { guest_hash = '' } } }: any = this.activatedRoute;
            if (guest_hash) {
                window.location.href = `${environment.btnImClientApp}/${lang}/profile/appointments?guest_hash=${guest_hash}`;
            } else {
                this.localize.changeLanguage(lang);
            }
            this.currentLanguage = lang === 'en' ? '' : lang;
        }
    }

    eventClose($event) {
        this.eventService.closed($event);
    }

    changeTitle(param) {
        this.titleService.setTitle(param);
    }

    // Loads every external script after user consents cookies
    allowScripts() {
        // this.loadScript('TRACKING_SCRIPT');
        /* Segment script */
        /* if (!this.scriptsAllowed) {
            this.loadContentScript(`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var t=analytics.methods[e];analytics[t]=analytics.factory(t)}analytics.load=function(e,t){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+e+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=t};analytics.SNIPPET_VERSION="4.1.0";analytics.load(\'${environment.segmentKey}\');}}();`);
            this.scriptsAllowed = true;
        } */
    }

    loadScript(url) {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    loadContentScript(content) {
        const node = document.createElement('script');
        node.innerHTML = content;
        node.type = 'text/javascript';
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    loadGTMScriptAgeMD() {
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TLGS5SL');`;
        const headOfDocument = document.getElementsByTagName('head')[0];
        headOfDocument.insertBefore(script, headOfDocument.firstChild);

        const iframe = document.createElement('iframe');
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TLGS5SL';
        iframe.height = '0';
        iframe.width = '0';
        iframe.style.display = 'none';
        iframe.style.visibility = 'hidden';

        const noScript = document.createElement('noscript');
        noScript.appendChild(iframe);
        const bodyOfDocument = document.getElementsByTagName('body')[0];
        bodyOfDocument.insertBefore(noScript, bodyOfDocument.firstChild);
    }
}
