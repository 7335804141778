import {timer as observableTimer, of } from 'rxjs';
import {switchMap, map, debounceTime, distinctUntilChanged, take} from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class ValidationService {
    messages = {};

    constructor(private translate: TranslateService,
                private http: HttpClient,
                private authService: AuthService,
                private apiService: ApiService) {
        this.messages = {
            required: _('VALIDATION.REQUIRED'),
            minlength: _('VALIDATION.MIN_LENGTH'),
            maxlength: _('VALIDATION.MAX_LENGTH'),
            emailValid: _('VALIDATION.EMAIL_VALID'),
            emailExists: _('VALIDATION.EMAIL_EXIST'),
            checksRequired: _('VALIDATION.CHECKS_REQUIRED'),
            phonePattern: _('VALIDATION.PHONE_PATTERN'),
            numberPositive: _('VALIDATION.NUMBER_POSITIVE'),
            numberBiggerThanZero: _('VALIDATION.NUMBER_BIGGER_THAN_ZERO'),
            URLValid: _('VALIDATION.URL_VALID'),
            matDatepickerParse: '', // Doesn't need key, simple placeholder
        };
    }

    getErrorMessages(errors) {
        const error_messages = [];
        if (errors != null) {
            const error_keys = Object.keys(errors);
            for (const key of error_keys) {
                if (this.messages[key]) {
                    const values = typeof errors[key] === 'object' ? errors[key] : {};
                    error_messages.push(this.translate.instant(this.messages[key], values));
                } else {
                    console.log('No message for validation key: ' + key);
                }
            }
            return error_messages;
        } else {
            return error_messages;
        }
    }

    getErrorMessage(errors) {
        const error_messages = this.getErrorMessages(errors);
        return error_messages.length > 0 ? error_messages[0] : '';
    }

    emailValid(c: FormControl) {
        // RFC 2822 compliant regex
        if (c.value === '' || c.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            return null;
        } else {
            return {emailValid: true};
        }
    }

    URLValid(c: FormControl) {
        // RFC 2822 compliant regex
        if (c.value === '' || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(c.value)) {
            return null;
        } else {
            return {URLValid: true};
        }
    }

    guestEmailAvailable(c: FormControl) {
        return observableTimer(200).pipe(
            switchMap(() => {
                return this.authService.guestEmailAvailable(c.value).pipe(map((res) => {
                    return res === true ? null : {emailAvailable: true};
                }, (error) => {
                    console.error(error);
                    return {emailAvailable: true};
                }));
            }));
    }


    checksRequired(minimum) {
        return (g: FormGroup) => {
            let checked = 0;
            if (g['controls']) {
                const keys = Object.keys(g['controls']);
                for (const key of keys) {
                    if (g['controls'][key]['value'] === true) {
                        checked++;
                    }
                    if (checked >= minimum) {
                        return null;
                    }
                }
            }
            return {
                checksRequired: {
                    checksRequired: minimum,
                }
            };
        };
    }

    numberPositive(c: FormControl) {
        return c.value >= 0 ? null : {numberPositive: true};
    }

    phonePattern(c: FormControl) {
      if (c.value.toString().length > 0) {
        const regex = new RegExp(/^[ ]{0,1}[0-9-/()x+. #]{5,18}$/g);
        return regex.test(c.value) ? null : {phonePattern: true};
      }
    }

    numberBiggerThanZero(c: FormControl) {
        return c.value > 0 ? null : {numberBiggerThanZero: true};
    }
}
