import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormModule } from './form/form.module';
import { DialogConfirmModule } from './shared/dialog-confirm/module';
import { CookiesConsentModule } from './cookies_consent/cookies-consent-module';
import { ApiService } from './shared/api.service';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { Location } from '@angular/common';
import { CacheMechanism, LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from '@gilsdav/ngx-translate-router';
import { LocalizeRouterHttpLoader } from '@gilsdav/ngx-translate-router-http-loader';
import { AuthService } from './shared/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LoadingService } from './shared/loading.service';
import { ExpertService } from './shared/expert.service';
import { TeamService } from './shared/team.service';
import { AppointmentsService } from './profile/appointments/service';
import { EventService } from './shared/event.service';
import { DialogHelper } from './shared/dialog.helper';
import { ClickOutsideModule } from 'ng-click-outside';
import { TimezoneService } from './shared/timezone.service';
import { ValidationService } from './shared/validation.service';
import { ErrorService } from './shared/error.service';
import { IFrameService } from './shared/iframe.service';
import { CallModule } from './profile/call/call.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { HammerConfig } from './shared/hammer-config';
import {
    HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import 'hammerjs';

export function defaultLanguage(languages, cachedLang, browserLang) {
    return cachedLang ? cachedLang : browserLang;
}

const rootRoutes: Routes = [
    {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        children: [
            {
                path: '',
                loadChildren: () => import('./auth/page/module').then(m => m.AuthPageModule),
            },
        ],
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/appointments/module').then(m => m.ProfileAppointmentsModule),
    },
    {
        path: 'e',
        loadChildren: () => import('./expert/expert.module').then(m => m.ExpertModule),
    },
    {
        path: 't',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule),
    }
];

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, 'assets_clients/locales/', '.json?nocache=' + (new Date()).getTime());
                },
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(rootRoutes, { preloadingStrategy: PreloadAllModules }),
        LocalizeRouterModule.forRoot(rootRoutes, {
            parser: {
                provide: LocalizeParser,
                useFactory: (translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) => {
                    return new LocalizeRouterHttpLoader(translate, location, settings, http, 'assets_clients/locales.json');
                },
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            },
            cacheMechanism: CacheMechanism.Cookie,
            defaultLangFunction: defaultLanguage,
        }),
        SimpleNotificationsModule.forRoot(),
        BrowserAnimationsModule,
        MatProgressBarModule,
        MatDialogModule,
        FormModule,
        DialogConfirmModule,
        CookiesConsentModule,
        ClickOutsideModule,
        CallModule,
        HammerModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (authService: AuthService) => {
                return (): Promise<any> => authService.initializer();
            },
            multi: true,
            deps: [AuthService],
        },
        {
            provide: ErrorHandler,
            useClass: ErrorService,
        },
        ApiService,
        AuthService,
        ExpertService,
        TeamService,
        AppointmentsService,
        EventService,
        LoadingService,
        DialogHelper,
        TimezoneService,
        ErrorService,
        IFrameService,
        ValidationService,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig ,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance
        },
        HammerConfig,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
