import { Injectable } from '@angular/core';
import { ApiService } from '../../shared/api.service';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../shared/event.service';
import * as moment from 'moment-timezone';
import { AuthService } from '../../shared/auth.service';
import { Subject, Observable, Subscription } from 'rxjs';

@Injectable()
export class AppointmentsService {
    appointments = {
        all: [],
        pending: [],
        upcoming: [],
        past: [],
        pending_reschedule: [],
    };
    countdownInterval;
    changedSource: Subject<any> = new Subject<any>();
    changed: Observable<any> = this.changedSource.asObservable();

    subscriptionOEvent: Subscription = null;

    constructor(private apiService: ApiService,
                private translate: TranslateService,
                private eventService: EventService,
                private authService: AuthService) {
        if (!this.subscriptionOEvent) {
            this.subscriptionOEvent = eventService.oEvent.subscribe((data: any) => {
                if (['appointment_pending', 'appointment_status_approved', 'appointment_status_finished',
                    'appointment_status_canceled', 'appointment_rescheduled_request', 'appointment_rescheduled_confirmed'].indexOf(data['type']['key']) >= 0) {
                    this.getAppointments();
                }
            });
        }
    }

    getAppointments() {
        this.apiService.request({
            method: 'get',
            btnIm: true,
            url: '/appointments/guest',
            params: {
                guest_hash: this.authService.guest.hash,
            },
        }).subscribe((appointments) => {
            this.set(appointments);
        });
    }

    set(appointments) {
        for (let i = 0; i < appointments.length; i++) {
            appointments[i]['start'] = moment(appointments[i]['start']).format();
        }
        this.appointments.all = appointments;
        if (appointments.length > 0) {
            document.documentElement.style.setProperty('--dashboard-color', appointments[0].color);
            document.documentElement.style.setProperty('--dashboard-color-10-opacity', appointments[0].color + '10');
            document.documentElement.style.setProperty('--dashboard-color-20-opacity', appointments[0].color + '20');
        }
        this.order();
        if (this.countdownInterval) {
            clearInterval(this.countdownInterval);
        }
        this.countdownInterval = setInterval(() => this.order(), 3000);
    }

    async updateStatus(appointmentId, status, reason) {
        for (const i in this.appointments.all) {
            if (this.appointments.all.hasOwnProperty(i)) {
                if (this.appointments.all[i]['id'] === appointmentId) {
                    await new Promise((resolve, reject) => {
                        this.apiService.request({
                            method: 'put',
                            btnIm: true,
                            url: '/appointments/guest',
                            params: {
                                appointment_id: appointmentId,
                                status: status >= 20 ? 'cancel' : 'approve',
                                reason: reason,
                                guest_hash: this.authService.guest.hash,
                            },
                        }).subscribe(() => {
                            this.appointments.all[i]['status'] = status;
                            this.appointments.all[i]['type_meeting'] = status >= 20 ? 'past' : 'upcoming';
                            this.order();
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                                'success'
                            );
                        }, (error) => {
                            console.error(error);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                                'warn'
                            );
                        });
                    });
                    break;
                }
            }
        }
    }

    async acceptReschedule(appointmentId) {
        for (const i in this.appointments.all) {
            if (this.appointments.all.hasOwnProperty(i)) {
                if (this.appointments.all[i]['id'] === appointmentId) {
                    const { hash: guestHash } = this.authService.guest;
                    await new Promise((resolve, reject) => {
                        this.apiService.request({
                            method: 'put',
                            btnIm: true,
                            url: '/reschedule/accept/guest/',
                            params: {
                                appointment_id: appointmentId,
                                guest_hash: guestHash,
                            },
                        }).subscribe(() => {
                            this.appointments.all[i]['status'] = 1;
                            this.appointments.all[i]['type_meeting'] = 'upcoming';
                            this.order();
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.SUCCESS.DESC'),
                                'success'
                            );
                        }, (error) => {
                            console.error(error);
                            resolve();
                            this.eventService.print(
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.TITLE'),
                                this.translate.instant('DASHBOARD.APPOINTMENTS.NOTIFICATION.STATUS.ERROR.DESC'),
                                'warn'
                            );
                        });
                    });
                    break;
                }
            }
        }
    }

    sortBy(arr, key, type = 'asc') {
      return arr.sort((a, b) => {
        if (a[key] > b[key]) {
          return type === 'asc' ? -1 : 1;
        }
        if (a[key] < b[key]) {
          return type === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    order() {
        this.appointments.pending = [];
        this.appointments.upcoming = [];
        this.appointments.past = [];
        this.appointments.pending_reschedule = [];
        this.appointments.all.forEach((appointment) => {
            const start = moment(appointment['start']);
            appointment['start'] = appointment['start'].replace(/\s/g, 'T'); // Safari fix
            appointment['end'] = moment(appointment['start']).add(appointment['length_requested'], 'm').format();
            const timeLeft = moment(appointment['start']).diff(moment(), 'seconds');
            if (appointment['status'] === 0 && timeLeft >= 0) {
                appointment['from_now'] = moment(start).fromNow();
                this.appointments.pending.push(appointment);
            } else if (appointment['status'] === 1 && timeLeft > (0 - (60 * 60 * 6))) {
                const secondsToCall = start.diff(moment(), 'seconds');
                appointment['from_now'] = start.fromNow();
                appointment['can_call'] = secondsToCall <= (60 * 60) && secondsToCall >= (0 - (60 * 60 * 6));
                this.appointments.upcoming.push(appointment);
            } else if (appointment['status'] > 29 && timeLeft >= 0) {
                appointment['from_now'] = moment(start).fromNow();
                this.appointments.pending_reschedule.push(appointment);
            } else {
                this.appointments.past.push(appointment);
            }
        });
        this.appointments.upcoming = this.sortBy(this.appointments.upcoming, 'start', 'desc');
        this.appointments.past = this.sortBy(this.appointments.past, 'start', 'asc');
        this.changedSource.next();
    }
}
