import { EventEmitter, Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import * as Cookies from 'js-cookie';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class EventService {
    socket: SocketIOClient.Socket;
    initialCookieHashPrivate;
    eventsTypes = [];

    oEventSource: Subject<any> = new Subject<any>();
    oNotificationClosedSource: Subject<any> = new Subject<any>();

    oEvent: Observable<any> = this.oEventSource.asObservable();
    oNotificationClosed: Observable<any> = this.oNotificationClosedSource.asObservable();

    constructor(private translate: TranslateService,
                private notificationsService: NotificationsService) {
    }

    /**
     * Connect/Reconnect to socket
     */
    connect(socketParams = null) {
        // Disconnect if socket opened
        if (this.socket) {
            this.disconnect();
        }
        // Generate connect URL
        let connectURL = environment.btnImApiBase;
        const params = {};
        if ((socketParams || {}).guestHash) {
            params['guest_hash'] = socketParams.guestHash;
        }
        this.initialCookieHashPrivate = Cookies.get(environment.hashPrivateCookieName, {
            path: environment.cookiePath,
            domain: window.location.hostname,
            secure: environment.cookieSecure,
        });
        if (this.initialCookieHashPrivate) {
            params['hash_private'] = this.initialCookieHashPrivate;
        }
        if (Object.keys(params).length) {
            connectURL += '/?';
            for (const key in params) {
                if (params.hasOwnProperty(key)) {
                    connectURL += key + '=' + params[key] + '&';
                }
            }
            connectURL = connectURL.substr(0, connectURL.length - 1);
        }
        // Connect
        this.socket = io(connectURL, {
            transports: ['websocket'],
        });
        this.addSocketConnectListener();
    }

    addSocketConnectListener() {
        this.socket.on('connect', () => {
            this.addSocketListeners();
        });
    }

    addSocketListeners() {
        /* Detection of when a socket is disconnected - This event is not sent by our backend but by the socket itself.
            It is important to keep so that we remove notifications once the socket is disconnected. */
        this.socket.on('disconnect', () => {
            this.socket.removeAllListeners();
            this.addSocketConnectListener();
            this.removeAll();
        });
        // On connection callback
        this.socket.on('session', (response) => {
            this.setHashPrivate(response['hash_private']);
        });
        this.eventsTypes = [
            {
                key: 'appointment_pending',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_pending.TITLE'),
                content: this.translate.instant('EVENTS.appointment_pending.DESC'),
            },
            {
                key: 'appointment_status_approved',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_status_approved.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_approved.DESC'),
            },
            {
                key: 'appointment_status_finished',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_status_finished.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_finished.DESC'),
            },
            {
                key: 'appointment_status_canceled',
                type: 'success',
                url: false,
                title: this.translate.instant('EVENTS.appointment_status_canceled.TITLE'),
                content: this.translate.instant('EVENTS.appointment_status_canceled.DESC'),
            },
            {
                key: 'appointment_soon',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_soon.TITLE'),
                content: this.translate.instant('EVENTS.appointment_soon.DESC'),
            },
            {
                key: 'appointment_rescheduled_request',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_rescheduled_request.TITLE'),
                content: this.translate.instant('EVENTS.appointment_rescheduled_request.DESC'),
            },
            {
                key: 'appointment_rescheduled_confirmed',
                type: 'info',
                url: false,
                title: this.translate.instant('EVENTS.appointment_rescheduled_confirmed.TITLE'),
                content: this.translate.instant('EVENTS.appointment_rescheduled_confirmed.DESC'),
            },
        ];
        this.eventsTypes.forEach((eventType) => {
            this.socket.on(eventType.key, (event) => {
                if (event['seen'] === null) {
                    const title = this.translate.instant('EVENTS.' + eventType['key'] + '.TITLE', event);
                    const content = this.translate.instant('EVENTS.' + eventType['key'] + '.DESC', event);
                    this.notificationsService[eventType['type']](title, content, {
                        id: 'notification-' + event['id'] + '-' + eventType['key'],
                    });
                    this.oEventSource.next({
                        type: eventType,
                        data: event,
                    });
                }
            });
        });
    }

    /**
     * Set/Delete hashPrivate used for client management
     * @param sessionHashPrivate
     */
    setHashPrivate(sessionHashPrivate) {
        const currentCookieHashPrivate = Cookies.get(environment.hashPrivateCookieName, {
            path: environment.cookiePath,
            domain: window.location.hostname,
            secure: environment.cookieSecure,
        });

        if (this.initialCookieHashPrivate !== currentCookieHashPrivate) {
            this.connect();
        } else if (currentCookieHashPrivate !== sessionHashPrivate) {
            Cookies.set(environment.hashPrivateCookieName, sessionHashPrivate, {
                expires: 90,
                path: environment.cookiePath,
                domain: window.location.hostname,
                secure: environment.cookieSecure,
            });
        }
    }

    disconnect() {
        this.socket.close();
    }

    getEventTypeFromKey(key) {
        for (const eventType of this.eventsTypes) {
            if (eventType.key === key) {
                return eventType;
            }
        }
        return false;
    }

    closed($event) {
        const data = $event['id'].split('-');
        if (data.length === 3 && data[0] === 'notification') {
            this.socket.emit('seen', data[1]);
            const eventType = this.getEventTypeFromKey(data[2]);
            this.oNotificationClosedSource.next({
                type: eventType,
                data: data,
            });
        }
    }

    print(title, content = null, type = 'info', length = 10000) {
        this.notificationsService[type](title, content, {
            timeOut: length,
            showProgressBar: true,
            pauseOnHover: true,
            clickToClose: true,
        });
    }

    removeAll() {
        this.notificationsService.remove();
    }
}
