export const environment = {
    production: true,
    staging: true,
    btnImClientApp: 'https://webutton.com',
    btnImApiBase: 'https://api.webutton.com',
    expertApp: 'https://app.webutton.com',
    expertAppWithoutHttp: 'app.webutton.com',
    hashPrivateCookieName: 'webutton_HP',
    trackingCookie: 'wbutton_GDPR',
    cookiePath: '/',
    cookieDomain: 'webutton.com',
    cookieSecure: true,
    hashPublic: '/4HY"b=7Za88Jz2~\'M%>PPq],b.o|mm]Ud(Om4V<_,!sdUqhLXk?TBm2-+P;Ne$',
    stripePublishableKey: 'pk_test_JvbhvoA5LbeOG9CsdORXMKqZ00KvAsdazm',
    ipRegistryKey: 'fpinr0ulx5y9ri',
    brand: 'MeetFox',
    segmentKey: 'zG0PZlCIF68ghfnDwiTTjpZIWVPEgQqU',
    reCaptcha: '6LeFGW4oAAAAAOKEnYqNKSCH7mz47HEk9oPOX4aa',
};
