import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Injectable()
export class TimezoneService {
    timezone = 60;
    timezoneDifference = {
        negative: false,
        aheadBehindString: '',
        minutes: 0,
        hours: 0,
    };
    subscriptionLangChange: Subscription = null;

    constructor(private translate: TranslateService) {
        this.getDifference();
        if (!this.subscriptionLangChange) {
            this.subscriptionLangChange = translate.onLangChange.subscribe(() => {
                this.getDifference();
            });
        }
    }

    getDifference() {
        let minutes = this.timezone - moment().utcOffset();
        if (minutes < 0) {
            this.timezoneDifference.negative = true;
            this.timezoneDifference.aheadBehindString = this.translate.instant('TIMEZONE.AHEAD');
            minutes = Math.abs(minutes);
        } else {
            this.timezoneDifference.negative = false;
            this.timezoneDifference.aheadBehindString = this.translate.instant('TIMEZONE.BEHIND');
        }
        this.timezoneDifference.hours = Math.floor(minutes / 60);
        this.timezoneDifference.minutes = minutes % 60;
    }
}
