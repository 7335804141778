import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-dialog-confirm',
    template: `
        <p
            [innerHTML]="data['content']"></p>
        <div class="modal-buttons">
          <app-form-button
              (click)="no()"
              [dimmed]="true">
              {{data['no']}}
          </app-form-button>
          <app-form-button
              (click)="yes()">
              {{data['yes']}}
          </app-form-button>
        </div>
    `,
    styles: [`
        p {
            margin: 0 0 20px 0;
            text-align: center;
        }
        .modal-buttons {
          display: flex;
          justify-content: center;
        }
        .modal-buttons app-form-button {
          margin-right: 5px;
        }
    `]
})
export class DialogConfirmComponent {
    constructor(public dialogRef: MatDialogRef<DialogConfirmComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    no() {
        this.dialogRef.close(false);
    }

    yes() {
        this.dialogRef.close(true);
    }
}
