<simple-notifications
    [options]="notificationOptions"
    (destroy)="eventClose($event)"
    *ngIf="!(borderless || groupCall || roomCall)"></simple-notifications>
<mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
    style="position: fixed; top: 0; left: 0; width: 100%; z-index: 9999"
></mat-progress-bar>
<div
    id="wrapper"
    [ngClass]="{borderless: borderless, removePadding: roomCall || groupCall}">
    <div id="header" *ngIf="!(borderless || groupCall || roomCall || urlIncludesTeam)">
        <a class="logo" *ngIf="!expert.logo && !waitingForLogo && !urlIncludesExpert"></a>
        <img class="customlogo" *ngIf="expert.logo" [src]="expert.logo"
            [ngStyle]="{'margin': '4px', 'margin-left': '20px', 'max-height': '42px'}">
    </div>
    <div id="content">
        <router-outlet></router-outlet>
    </div>
</div>
<!-- <ng-container
    *ngIf="borderless === false && !custom_domain && (urlIncludesExpert || urlIncludesTeam)">
    <app-cookies-consent-component [urlDataPolicy]="urlDataPolicy" (o_consent)="allowScripts()">
    </app-cookies-consent-component>
</ng-container> -->